<template>
    <div>
        <div v-if="isLoaded">
            <Header />
            <Nav />
            <slot />
            <div v-show="isCookiePolicyPage" class="uc-embeddings">
                <h2>{{ $t('cookies.cookie-settings-headline') }}:</h2>
                <div
                    class="uc-embed"
                    uc-layout="privacySettings"
                    udg-layout="pagskin"
                />
            </div>
            <legal-rights :key="$route.name" />
            <Footer />
        </div>
        <loader v-if="!isLoaded && !isShowingOverlay" />
        <expired-session-overlay v-else-if="isSessionExpired" />
        <change-password-overlay v-else-if="isChangingPassword" />
        <subscribe-newsletter-overlay v-else-if="isSubscribingNewsletter" />
        <onboarding-overlay v-else-if="isViewingOnboarding" />
        <accept-terms-overlay v-else-if="isAcceptingTerms && !isPage" />
        <post-login-modal v-else-if="isShowAfterLoginModal" />
    </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Loader from '@/components/common/Loader';
import Header from '@/components/Header';
import Nav from '@/components/Nav';
import LegalRights from '@/components/LegalRights';
import Footer from '@/components/Footer/Footer.vue';
import CurrentUserMarketMixin from '@/components/current_user_market_mixin';
import ExpiredSessionOverlay from '@/views/auth/ExpiredSessionOverlay';
import ChangePasswordOverlay from '@/views/auth/ChangePasswordOverlay';
import SubscribeNewsletterOverlay from '@/views/auth/SubscribeNewsletterOverlay';
import OnboardingOverlay from '@/views/auth/OnboardingOverlay';
import AcceptTermsOverlay from '@/views/auth/AcceptTermsOverlay';
import PostLoginModal from '@/views/auth/PostLoginModal';

export default {
    components: {
        Loader,
        Header,
        Nav,
        LegalRights,
        Footer,
        ExpiredSessionOverlay,
        ChangePasswordOverlay,
        SubscribeNewsletterOverlay,
        OnboardingOverlay,
        AcceptTermsOverlay,
        PostLoginModal,
    },
    mixins: [CurrentUserMarketMixin],
    data() {
        return {
            isLoaded: false,
            notificationPollTimer: null,
        };
    },
    computed: {
        ...mapGetters([
            'currentUser',
            'isSessionExpired',
            'isChangingPassword',
            'isSubscribingNewsletter',
            'isViewingOnboarding',
            'isAcceptingTerms',
            'isShowAfterLoginModal',
        ]),
        isShowingOverlay() {
            return (
                this.isSessionExpired ||
                this.isChangingPassword ||
                this.isSubscribingNewsletter ||
                this.isViewingOnboarding ||
                this.isShowAfterLoginModal ||
                (this.isAcceptingTerms && !this.isPage)
            );
        },
        isPage() {
            return this.$route.name === 'page';
        },
        isCookiePolicyPage() {
            return ['cookies'].indexOf(this.$route.params.slug) > -1;
        },
    },
    created() {
        this.init();
    },
    beforeDestroy() {
        this.cancelPollNotifications();
    },
    methods: {
        ...mapActions([
            'getUser',
            'loadChannels',
            'loadMarkets',
            'loadPages',
            'loadEventWaitingList',
            'loadEventPrebooking',
        ]),
        ...mapMutations(['setCountUnreadNotifications', 'setUCLoaded']),
        async pollNotifications() {
            const {
                data: {meta},
            } = await this.$api.get('me/notifications?unread=1', {cache: false});
            this.setCountUnreadNotifications(meta.count);
        },
        cancelPollNotifications() {
            if (this.notificationPollTimer) clearInterval(this.notificationPollTimer);
        },
        async init() {
            await Promise.all([
                this.getUser(),
                this.loadChannels(),
                this.loadMarkets(),
                this.loadPages(),
                this.loadEventWaitingList(),
                this.loadEventPrebooking(),
            ]);

            this.isLoaded = true;
            this.pollNotifications();
            this.notificationPollTimer = setInterval(() => this.pollNotifications(), 30 * 1000);

            // Show ChangePasswordOverlay?
            if (this.currentUser.password_age === null) {
                this.$store.commit('setChangingPassword', true);
            }

            // Show SubscribeNewsletterOverlay?
            if (this.currentUser.newsletter_subscription === null) {
                this.$store.commit('setSubscribingNewsletter', true);
            }

            // Show OnboardingOverlay?
            if (!this.currentUser.has_viewed_current_onboarding) {
                this.$store.commit('setViewingOnboarding', true);
            }

            // Show AcceptTermsOverlay?
            if (
                this.currentUser.has_accepted_terms === null ||
                (this.currentUser.has_accepted_terms && !this.currentUser.has_accepted_current_terms)
            ) {
                this.$store.commit('setAcceptingTerms', {
                    isAcceptingTerms: true,
                    callback: async hasAccepted => {
                        if (hasAccepted) {
                            await this.$router.push({
                                name: 'profile',
                                params: {
                                    link: {
                                        name: this.$route.name,
                                        params: this.$route.params,
                                    },
                                },
                            });
                        }
                    },
                });
            }

            // if (this.currentUser.welcome_present === null) {
            //   this.$router.push({ name: 'welcome-present' });
            //   return;
            // }

            if (this.currentUserMarket.abbr === 'PCNA') {
                return;
            }

            const languageCode = this.currentUser.language.code.split('_')[0];

            this.$loadScript('https://app.usercentrics.eu/latest/main.js', {
                id: 'TRl_d0xIs',
                'data-language': languageCode,
            }).then(() => {
                this.setUCLoaded(true);
            });
        },
    },
};
</script>
<style scoped>
.uc-embeddings {
    margin: 0 auto;
    width: 90%;
    max-width: 690px;
}
</style>
