<template>
    <overlay center>
        <div class="post-login-modal">
            <div class="post-login-content">
                <h1 class="post-login-headline" v-text="$t('login_banner.headline')" />
                <p class="post-login-text" v-html="$t('login_banner.content')"></p>
            </div>
            <div class="post-login-actions">
                <button class="post-login-button" @click="accept" v-text="$t('login_banner.button_text')" />
            </div>
        </div>
    </overlay>
</template>

<script>
import Overlay from '@/components/Overlay';

export default {
    name: 'PostLoginModal',
    components: {
        Overlay,
    },

    methods: {
        accept: function () {
            this.$store.commit('setShowAfterLoginModal', false);
        },
    },
};
</script>

<style lang="scss">
.post-login-modal {
    background-color: #fff;
    max-width: 636px;
    padding: 32px;
    display: flex;
    flex-direction: column;

    .post-login-content {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .post-login-headline {
            color: #010205;
            font-family: "Porsche Next";
            font-size: 20.54px;
            font-style: normal;
            font-weight: 600;
            line-height: 29.1px;
        }

        .post-login-text {
            color: #010205;
            font-family: "Porsche Next";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .post-login-actions {
        padding-top: 32px;
        display: flex;
        justify-content: flex-start;

        .post-login-button {
            cursor: pointer;
            background: transparent;
            border: 2px solid #010205;
            padding: 15px 28px;
            border-radius: 4px;
            flex-grow: 1;
            align-self: flex-start;

            @media (min-width: 480px) {
                flex-grow: 0;
            }

            &:hover {
                background-color: rgba(148, 149, 152, 0.18);
                border-color: rgb(107, 109, 112);
                backdrop-filter: blur(32px);
            }
        }
    }
}
</style>
